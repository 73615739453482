import valueMap from '../flagValueMapper';

// valuemap(uat, prod, local, stag, dev)

const config = {
  dashboard: {
    osvtltip: valueMap(true, true, true, true, true),
    refineryLiftingTracker: valueMap(true, true, true, true, true),
    operationalhighlights: valueMap(true, true, true, true, true),
    operationalalerts: valueMap(true, true, true, true, true),
    bunkervlf: valueMap(true, true, true, true, true),
    autogr: valueMap(true, true, true, true, true),
    pliui: valueMap(true, true, true, true, true),
    vesseltracker: valueMap(true, true, true, true, true),
    thirdparty: valueMap(false, false, true, true, true),
    salesTrend: valueMap(false, false, true, true, true),
    mlsmanual: valueMap(true, true, true, true, true),
    mlsAutomation: valueMap(false, false, true, true, true),
    mlsEditing: valueMap(false, false, true, true, true),
    mlsRunningNo: valueMap(false, false, true, true, true),
    mlscommentbox: valueMap(false, false, true, true, true),
  },
};
export default config;
