import valueMap from '../flagValueMapper';

// Retail EO persona and environment configuration
// valuemap(uat, prod, local, stag, dev)

const config = {
  pricing: {
    regionFilterFix: valueMap(false, false, true, true, true),
    dailyHistoryLoad: valueMap(false, false, true, true, true), // remove once indra history load test finished
    dtpEo: valueMap(true, true, true, true, true),
  },
};
export default config;
