import valueMap from '../flagValueMapper';

// Retail EO persona and environment configuration
// valuemap(uat, prod, local, stag, dev)

const config = {
  cbdTable: {
    runPowerBIButton: valueMap(false, false, false, false, false),
  },
  cpadtp: {
    mvp2: valueMap(false, false, true, true, true),
    dtpEditing: valueMap(false, false, true, true, true),
  },
};
export default config;
