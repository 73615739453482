import valueMap from '../flagValueMapper';

// Retail PETCO user persona and environment configuration
// valuemap(uat, prod, local, stag, dev)

const config = {
  vrDashboard: {
    testWdget: valueMap(false, false, false, false, false),
    mtbeEnhance: valueMap(true, true, true, true, true),
    thirdPartyVR: valueMap(true, true, true, true, true),
  },
};
export default config;
