/* eslint-disable complexity */

/**
 * @description - Get  base url
 * @param {*} hostName - Endpoint.
 * @returns {*} - Base url with endp point.
 */
export function getCurrentEnv(hostName) {
  const key = hostName.split('.')[0];
  switch (key) {
    case 'uat':
      return 'uat';
    case 'localhost':
      return 'local';
    case 'dev':
      return 'dev';
    case 'stag':
      return 'stag';
    default:
      // dove
      return 'prod';
  }
}

/**
 * @description - Get base url with end point.
 * @param {*} flag - Endpoint.
 */
export function isValidEnv(flag) {
  const { hostname } = window.location;
  const envKey = getCurrentEnv(hostname);
  const featureValidation = flag[envKey];
  return featureValidation;
}
